import { BrowserClient, createBoundLocalization } from '@wf-mfe/localize-react'

const localizationClient = new BrowserClient({
  namespace: ['redrock'],
  enableTerminology: true,
})

const getTextSync = localizationClient.getTextSync.bind(localizationClient)

const clientFactory = () => localizationClient

const { Localization, useLocalization, useLocalizations } =
  createBoundLocalization(localizationClient)

export {
  localizationClient,
  getTextSync,
  clientFactory,
  Localization,
  useLocalization,
  useLocalizations,
}
