import { useLocalization } from '../utils'
import { SetupLayout, SetupLayoutContent } from '@setup/components'
import AccessLevelsIcon from 'phoenix-icons/dist/LockExtraLargeHeroIcon.js'
import { AccessLevelsPage } from './AccessLevelsPage'
import * as React from 'react'

const CONTEXT_SENSITIVE_HELP_PAGE_ID = 'csh-list-access-levels'

export function AccessLevelsSetupPage() {
  const [accessLevelTitle] = useLocalization(
    'accesslevel.plural',
    'Access Levels'
  )

  return (
    <SetupLayout
      contextSensitiveHelpPageID={CONTEXT_SENSITIVE_HELP_PAGE_ID}
      headerIcon={<AccessLevelsIcon />}
      title={accessLevelTitle}
    >
      <SetupLayoutContent>
        <AccessLevelsPage />
      </SetupLayoutContent>
    </SetupLayout>
  )
}
