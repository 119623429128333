import React from 'react'
import { WrappedListContainer } from '@wf-mfe/lists'
import {
  ListActionBaseDialog,
  deleteDialogFactory,
  DeleteConfirmationDialog,
} from '@wf-mfe/dialogs'
import { AccessLevel } from 'workfront-objcodes'
import { listTileParameters } from '../pages/List/list-tile-parameters'

export const AccessLevelPageId = 'list-access-levels'
//minor fix to make it look a little better
const styles = `
#layout-content.page-content-width{
  width: 100% !important;
  box-sizing: border-box;
  min-width: 0 !important;
}
#layout-container{
    padding: 25px !important;
}
`

export function AccessLevelDialog(DialogProps) {
  const { data, onClose } = DialogProps
  let headerTextMessageKey
  switch (data.data.dialogName) {
    case 'add-acslvl-dialog':
      headerTextMessageKey = 'accesslevel.action.add'
      break
    case 'copy-acslvl-dialog':
      headerTextMessageKey = 'accesslevel.action.copy'
      data.data.tileParameters.content = 'tab-access-level-builder-copy-form'
      data.data.tileParameters.tileID = 'tab-access-level-builder-copy-form'
      break
    case 'edit-acslvl-dialog':
      headerTextMessageKey = 'accesslevel.action.edit'
      break
  }

  return (
    <ListActionBaseDialog
      hideSubHeader
      data={data}
      onClose={onClose}
      headerTextMessageKey={headerTextMessageKey}
      dialogProps={{
        height: 500,
        width: 800,
      }}
      kaminoShimProps={{
        height: 500,
        width: '100%',
        legacyScripts: ['static/js/setup.js'],
        legacyStylesheets: ['content-setup'],
        styles,
      }}
      injectButtons
    />
  )
}

export function accessLevelDialogFactory() {
  return {
    'add-acslvl-dialog': ({ data, onClose, reloadParentTile }) => (
      <AccessLevelDialog
        data={data}
        onClose={() => {
          onClose()
          reloadParentTile()
        }}
      />
    ),
    'copy-acslvl-dialog': ({ data, onClose, reloadParentTile }) => (
      <AccessLevelDialog
        data={data}
        onClose={() => {
          onClose()
          reloadParentTile()
        }}
      />
    ),
    'edit-acslvl-dialog': ({ data, onClose, reloadParentTile }) => (
      <AccessLevelDialog
        data={data}
        onClose={() => {
          onClose()
          reloadParentTile()
        }}
      />
    ),
    'delete-acslvl-dialog': deleteDialogFactory({
      Component: (props) => (
        <DeleteConfirmationDialog
          {...props}
          objCode={AccessLevel}
          forceDelete={true}
        />
      ),
    }),
  }
}

export function AccessLevelsPage() {
  const accessLevelTileParameters = listTileParameters(AccessLevelPageId)

  return (
    <WrappedListContainer
      listName={AccessLevelPageId}
      listID={AccessLevelPageId}
      tileName={AccessLevelPageId}
      dialogs={accessLevelDialogFactory()}
      tileParameters={accessLevelTileParameters}
      detailObject={{
        objCode: AccessLevel,
        ID: null,
      }}
    />
  )
}
