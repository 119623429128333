export const defaultTileParametersFn =
  (objCode) =>
  ({ match, params }) => ({
    detailObjCode: objCode,
    detailObjID: params?.ID ?? match.params.ID,
  })

export const listTileParameters =
  (objCode) =>
  (tileID, parameters = {}) =>
  (args) => ({
    [`${tileID}-pageNumber`]: '',
    [`${tileID}-pageSize`]: '100',
    ...defaultTileParametersFn(objCode)(args),
    ...parameters,
  })
